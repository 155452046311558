<template>
  <div>
    <Login/>
    <div class="zone-content-form wf-section" v-if="ready">
      <div class="container-content-form-suite w-container">
        <div class="w-layout-grid grid-form">
          <div class="div-precedent"></div>
          <div class="div-content-form">
            <p class="form-titre-page merci">Votre locataire vous remercie !<span class="text-span-9"></span></p>
            <p class="form-2nd-titre-merci">Votre signature a bien été prise en compte et votre contrat est dorénavant validé.<span class="text-span-9"></span></p>
            <p class="texte-merci">Vous allez recevoir un mail de confirmation avec en pièce jointe votre contrat, ses conditions générales ainsi que le document d&#x27;information sur l&#x27;assurance. <span class="text-span-26">Félicitations, vous venez de rejoindre le club Pautions :).</span><span class="text-span-9"></span></p>
            <a href="javaScript:void(0);" @click="change()" class="bouton-end-souscri w-button">JE ME RENDS SUR MON ESPACE PAUTIONS</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Login from '@/components/Menu/Utilisateur';
export default {
  name: "FStep10",
  components: {
    Login
  },
  data(){
    return {
      ready:false,
    }
  },
  computed: {
  },
  watch:{
  },
  methods: {
    change(){
      location = '/#/accueil'
    }
  },
  mounted() {
    this.ready = true;
    if(this.$route.query.event === "signing_complete" && this.$route.query.uuid != undefined && this.$route.query.role == "4"){
      this.$store.api.ajax('/zero-depot/proprietaire/'+this.$route.query.uuid, null, res => {
        this.$gtag.event('actif_souscrit', { method: 'actif_souscrit' });
        return res;        
      })
    }
  },
  beforeCreate() {
    document.getElementsByTagName('body')[0].className = "body-form";
  },
};
</script>
<style>
.bouton-paiement-type-rib{
  background-color: #242089;
  background-image: url(/img/ic_rib_blanc.56d983c4.svg);
  color: #fff;
}
.bouton-paiement-type-cb{
  background-color: #242089;
  background-image: url(/img/ic_cb_blanc.09fc00ab.svg);
  color: #fff;
}
.prix-mensuel-select{
  color: #fff;
  font-weight: 700;
}
@media only screen and (max-width: 600px) {
  .w-layout-grid.grid-form {
    display: block !important;
  }
}
</style>
